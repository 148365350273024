body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height:100%;
}

body {
  margin:0;
}

.header {
  margin: 0;
  font-family: "Raleway", sans-serif;
  height: 10vh;
}

.outlet {
  margin: 0;
  height: 80vh;
}

.footer {
  margin: 0;
  height: 10vh;
}